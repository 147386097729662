import React, { useState } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import style from "./auth.module.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { login } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const Login = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
     <Helmet>
        <title>Login - I Stand With The Forgotten Women</title>
        <meta name="description" content="Struggling to log in? Enter your email, password, and check Remember Me.Forgot your password? Create a new account easily!" />
        <meta name="keywords" content="Login,Email address,Password,Remember me,Forgot password,Create account,Authentication,User login,Account access,Secure login"/>
      </Helmet>
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "70vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} md={6} sm={8} lg={4} className="mx-auto p-0">
          <div className={style.authBox}>
            <h3 className="text-center mb-4">Login</h3>
            <Formik
              initialValues={{
                email: "",
                password: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  setStatus({ success: false });
                  setSubmitting(false);
                  dispatch(login(values,navigate))
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      className=
                        {`${style.custominput}  ${touched.email && errors.email ? "is-invalid" : ""}`}
                      
                      type="email"
                      placeholder="Enter email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <Form.Text className=" text-danger">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={
                          
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        id="password"
                        //  type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.password && errors.password)}
                      />
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </InputGroup>

                    {touched.password && errors.password && (
                      <Form.Text className=" text-danger">
                        {errors.password}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Row className="m-0 p-0">
                      <Col className="p-0">
                        <Form.Check type="checkbox" label="Remember me" />
                      </Col>
                      <Col className="p-0 text-end">
                        <Link to="/forget-password">Forgot Your Password?</Link>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Button className="w-100 authBlubtn" variant="primary" type="submit">
                    Login
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4">
              Don’t have an account? <Link to="/register">Create</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Login;
