import React from "react";
import style from "./book.module.css";

const Matters = () => {

  return (
    <div className={style.matters_wrapper}>
      <div className="container">

        <h3 className={style.matters_h3}>This 'Blueprint' is the only solution that can break the chain of intergenerational abortions that lead to unplanned pregnancies.</h3>
        <p className={style.matters_p}>Get your copy of<span> The Blueprint to ending abortion in the church </span>today and begin equipping your church with the tools it needs to navigate the complexities of abortion with biblical integrity, compassion, and practical insight.
          Your members will thank you for it.</p>

        <div className={`text-center ${style.book_done_button}`}>
          <a href="https://blog.istandwiththeforgottenwomen.org/product/blueprint-to-ending-abortion-in-the-church/" target="_blank">
          <button >Order Now</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Matters;
