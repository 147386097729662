import React from "react";
import style from "./aboutus.module.css";
import { Helmet } from "react-helmet";

const Churchesabout = () => {

  return (
    <>
   <Helmet>
    <title>Church -I Stand With Forgotten Women</title>
    <meta name="description" content="Discover how churches and pregnancy resource centers form a powerful partnership, offering holistic care, support, and hope to individuals in need through shared values. " />
    <meta name="keywords" content="Church support,Pregnancy resource centers,Unplanned pregnancies,Spiritual guidance,Compassionate care,Sanctity of life,Community of faith,Holistic support,Promoting dignity,Collaborative partnership"/>
  </Helmet>
    <div className={style.backcolor}>
        <div className={style.churchestext}>
   
        <div  className="container">
        <div className={style.churchtextp}>
        <h1>The Vital Partnership: Churches and Pregnancy Resource Centers</h1>
         <p>The connection between churches and pregnancy resource centers can be seen as a divinely inspired partnership, where each entity enhances the other's mission. Pregnancy resource centers act as practical extensions of the church’s outreach, delivering tangible support, resources, and compassionate care to those navigating unplanned pregnancies and related difficulties.</p>

            <p>In turn, churches provide spiritual counsel, moral encouragement, and a nurturing faith community for individuals served by these centers. Together, they create a dynamic alliance rooted in compassion, love, and a shared dedication to preserving the sanctity of life while supporting those in need. Just as successful partnerships rely on mutual respect, collaboration, and aligned values, the relationship between churches and pregnancy resource centers flourishes when united by a common purpose: to promote life, dignity, and hope.
            </p>

            <p>
            Through their joint efforts, this partnership offers comprehensive care and support to individuals and families, demonstrating the transformative impact of love and grace in action.
            </p>
            </div>
        </div>
        </div>
        </div>
        </>
  );
};

export default Churchesabout;
