import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import style from "./auth.module.css";
// import { Link } from "react-router-dom";
import { State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { registerCenter } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import CenterRegisterSuccess from "../../modals/centerRegisterSuccess/CenterRegisterSuccess";
import CenterSucess from "./Centersucess";
import { Helmet } from 'react-helmet';

const CenterRegister = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const countrylist = [
    {
      isoCode: "CA",
      name: "Canada",
    },
    {
      isoCode: "MX",
      name: "Mexico",
    },
    {
      isoCode: "PR",
      name: "Puerto Rico",
    },
    {
      isoCode: "US",
      name: "United States",
    },
  ];
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showSuccessModal, setShowSucessModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    // setCountries(Country.getAllCountries());
    setCountries(countrylist)
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
      setSelectedState("");
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && selectedCountry) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
      setSelectedCity("");
    }
  }, [selectedState, selectedCountry]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
    <Helmet>
    <title>Center-register - I Stand With The Forgotten Women</title>
    <meta name="description" content="Connect with women in need by registering your pregnancy center, maternity home, or adoption agency. Provide essential support and make a lasting impact." />
    <meta name="keywords" content="Pregnancy center,Maternity home,Adoption agency,Register center,Essential support,Contact information,City and state,Zip code,Phone number,Network registration"/>
  </Helmet>
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} sm={8} md={6} lg={8} className="mx-auto p-0">
          <div className="text-center mt-4">
            <h3 className=" mb-3 ">Join Our Network</h3>
            <div className=" w-75 m-auto">
              Register your pregnancy center, maternity home, or adoption agency
              to connect with women in need and provide essential support.
              Together, we can make a significant impact.
            </div>
          </div>
          <div className={style.authBox}>
            <h3 className="text-center mb-4 ">Register Your Center</h3>
            <Formik
              initialValues={{
                centerName: "",
                contactPersonName: "",
                email: "",
                password: "",
                country: "",
                state: "",
                city: "",
                phone: "",
                address: "",
                zipCode: "",
              }}
              validationSchema={Yup.object().shape({
                centerName: Yup.string().required(" Center Name is required"),
                contactPersonName: Yup.string()
                  .max(255)
                  .required("Contact Person Name is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
                country: Yup.string().required("Country is required"),
                state: states.length
                  ? Yup.string().required("State is required")
                  : "",
                city: cities.length
                  ? Yup.string().required("City is required")
                  : "",
                phone: Yup.string()
                  .matches(phoneRegExp, "Phone number is not valid")
                  .required("Phone is required"),
                address: Yup.string().required("Address is required"),
                zipCode: Yup.string().required("Zip code is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                setDisableButton(true);
                try {
                  setStatus({ success: false });
                  setSubmitting(false);
                  dispatch(registerCenter(values)).then((res) => {
                    if (res) {
                      resetForm();
                      navigate('/thank-you');
                      setShowSucessModal(true);
                      setDisableButton(false);
                    }
                    setDisableButton(false);
                  });
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                  setDisableButton(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="m-0 p-0">
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formCenterName">
                        <Form.Label>Name of the center</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter the center name"
                          className={
                            touched.centerName && errors.centerName
                              ? "is-invalid"
                              : ""
                          }
                          value={values.centerName}
                          name="centerName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.centerName && errors.centerName
                          )}
                        />
                        {touched.centerName && errors.centerName && (
                          <Form.Text className=" text-danger">
                            {errors.centerName}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicLastName"
                      >
                        <Form.Label>Contact Person Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Person Name"
                          className={
                            touched.contactPersonName &&
                            errors.contactPersonName
                              ? "is-invalid"
                              : ""
                          }
                          value={values.contactPersonName}
                          name="contactPersonName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.contactPersonName &&
                              errors.contactPersonName
                          )}
                        />
                        {touched.contactPersonName &&
                          errors.contactPersonName && (
                            <Form.Text className=" text-danger">
                              {errors.contactPersonName}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          className=
                            {`${style.custominput}  ${touched.email && errors.email ? "is-invalid" : ""}`}
                            // touched.email && errors.email ? "is-invalid" : ""
                          
                          type="email"
                          placeholder="Enter email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email && (
                          <Form.Text className=" text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={
                              touched.password && errors.password
                                ? "is-invalid"
                                : ""
                            }
                            type={showPassword ? "text" : "password"}
                            placeholder="********"
                            value={values.password}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.password && errors.password)}
                          />
                          <InputGroup.Text
                            style={{ cursor: "pointer" }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>

                        {touched.password && errors.password && (
                          <Form.Text className=" text-danger">
                            {errors.password}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicCountry">
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          // onChange={handleCountryChange}
                          // value={selectedCountry}
                          className={
                            touched.country && errors.country
                              ? "is-invalid"
                              : ""
                          }
                          value={values.country}
                          name="country"
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleChange(e);
                            handleCountryChange(e);
                            setFieldValue("country", e.target.value);
                          }}
                          error={Boolean(touched.country && errors.country)}
                        >
                          <option value="" disabled>
                            Select Country
                          </option>
                          {countries.map((country) => (
                            <option
                              key={country.isoCode}
                              value={country.isoCode}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {touched.country && errors.country && (
                          <Form.Text className=" text-danger">
                            {errors.country}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicstate">
                        <Form.Label>State </Form.Label>
                        <Form.Select
                          // onChange={handleStateChange}
                          // value={selectedState}
                          disabled={!selectedCountry}
                          className={
                            touched.state && errors.state ? "is-invalid" : ""
                          }
                          value={values.state}
                          name="state"
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleChange(e);
                            handleStateChange(e);
                            setFieldValue("state", e.target.value);
                          }}
                          error={Boolean(touched.state && errors.state)}
                        >
                          <option value="" disabled>
                            Select State
                          </option>
                          {states.map((state) => (
                            <option key={state.isoCode} value={state.isoCode}>
                              {state.name}
                            </option>
                          ))}
                        </Form.Select>
                        {touched.state && errors.state && (
                          <Form.Text className=" text-danger">
                            {errors.state}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasiCity">
                        <Form.Label>City </Form.Label>
                        <Form.Select
                          // onChange={handleCityChange}
                          // value={selectedCity}
                          disabled={!selectedState}
                          className={
                            touched.city && errors.city ? "is-invalid" : ""
                          }
                          value={values.city}
                          name="city"
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleChange(e);
                            handleCityChange(e);
                            setFieldValue("city", e.target.value);
                          }}
                          error={Boolean(touched.city && errors.city)}
                        >
                          <option value="" disabled>
                            Select City
                          </option>
                          {cities.map((city) => (
                            <option key={city.name} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </Form.Select>
                        {touched.city && errors.city && (
                          <Form.Text className=" text-danger">
                            {errors.city}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formPhone">
                        <Form.Label>Phone number</Form.Label>
                        <PhoneInput
                          name="phone"
                          country={"us"}
                          placeholder="Enter your phone number"
                          enableSearch={true}
                          inputClass={` w-100
                          ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                          value={values.phone}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("phone", e);
                            handleChange(e);
                          }}
                          error={Boolean(touched.phone && errors.phone)}
                        />
                        {touched.phone && errors.phone && (
                          <Form.Text className=" text-danger">
                            {errors.phone}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formAddress">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Enter your address"
                          className={
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }
                          value={values.address}
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.address && errors.address)}
                        />
                        {touched.address && errors.address && (
                          <Form.Text className=" text-danger">
                            {errors.address}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formZipCode">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter zip code"
                          className={
                            touched.zipCode && errors.zipCode
                              ? "is-invalid"
                              : ""
                          }
                          value={values.zipCode}
                          name="zipCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                        />
                        {touched.zipCode && errors.zipCode && (
                          <Form.Text className=" text-danger">
                            {errors.zipCode}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={{ span: 6, offset: 3 }}>
                      <Button
                        className="w-100 authBlubtn"
                        variant="primary"
                        type="submit"
                        disabled={disableButton}
                      >
                        Register Now
                        {disableButton && (
                          <Spinner
                            className="ms-3"
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                  {/* <Row> */}
                  {/* </Row> */}
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4">
              {/* Already have account? <Link to="/login">Login</Link> */}
            </div>
          </div>
        </Col>
      </Row>
      {
        <CenterRegisterSuccess
          showSuccessModal={showSuccessModal}
          setShowSucessModal={setShowSucessModal}
          
        />
      }
    </div>
    </>
  );
};

export default CenterRegister;
