import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import style from "./homeheader.module.css";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../utils/api";
import { Col, Dropdown, Nav, Navbar, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../../customHook/useGetUser";
import { logout } from "../../../../apis/authApis";

const HomeHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();

  useGetUser();
  const { user } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);

  const [active, setActive] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  const [showAboutDropdown, setShowAboutDropdown] = useState(false);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const scrollToSection = (id) => {
    navigate(`/aboutus#${id}`);
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setShowAboutDropdown(false); // Close the dropdown after scrolling
    }
  };

  return (
    <>
      <div className={style.headbaground}>
        <div className="container">
          <div className={style.headerhead}>
            <p className="mb-0">
              {" "}
              <span>24 Hour Hotline: </span>(800) 712-Help(4357)
            </p>
          </div>
        </div>
      </div>
      <div className={style.header_main}>
     
        <div className={style.mobilenavbar}>
          <Navbar
            expand="lg"
            expanded={expanded}
            className={style.mobilenavbar}>
            {/* <Row className="align-items-center w-100">
              <Col xs={6}> */}
                <Navbar.Brand as={Link} to="/" onClick={() => setActive(1)}>
                  <img
                    className={style.header_logo}
                    src="/whitelogo.svg"
                    alt="mobilelogo"
                  />

              <Nav className={style.navbar}>
                <Link
                  className="p-1 border-0"
                  to="/aboutus/"
                  onClick={handleToggle}>
                  About Us
                </Link>
                <Link
                  target="_blank"
                  className="p-1 border-0"
                  to="https://supportafterabortion.com/resources/unraveled-roots/"
                  onClick={handleToggle}>
                  Unraveled Roots
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/SupportMen.pdf"
                  target="_blank"
                  onClick={() => {
                    setActive("/SupportMen.pdf");
                    setExpanded(false);
                  }}>
                  Impact on Men
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/center-register"
                  onClick={handleToggle}>
                  Affiliate-sign up
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/hbcu"
                  onClick={() => setActive("/HBCU Project")}>
                  HBCU Project
                </Link>

                {!token && (
                  <Link
                    className=" p-1 border-0"
                    to="/login/"
                    onClick={handleToggle}>
                    Login
                  </Link>
                )}
                {token && (
                  <div ref={dropdownRef}>
                    <Link
                      className=" p-1 border-0"
                      onClick={() => setShowDropdown(!showDropdown)}>
                      {user?.firstName}
                    </Link>

                    <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                      <Dropdown.Item
                        onClick={() => {
                          handleToggle();
                          setShowDropdown(false);
                          setActive();
                          setExpanded(false); // Close the navbar only after the action
                          navigate("/profile");
                        }}>
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          setExpanded(false); // Close the navbar only after the action
                          dispatch(logout({ navigate }));
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                )}
                {/* <button
                    className={`${style.loginbtn}`}
                    onClick={() => {
                      setActive();
                      navigate("/donate-to-us");
                      setExpanded(false);
                    }}>
                    Donate
                  </button> */}
              </Nav>
                </Navbar.Brand>
              {/* </Col> */}
              {/* <Col xs={6} className="text-right"> */}
                {/* <div className={style.navbartoggle}>
                  <Navbar.Toggle
                    className={style.navbartogglericon}
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(!expanded)} // Toggle properly without premature closing
                  />
                </div> */}
              {/* </Col> */}
            {/* </Row> */}

            {/* <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={style.navbar}>
                <Link
                  className="p-1 border-0"
                  to="/aboutus/"
                  onClick={handleToggle}>
                  About Us
                </Link>
                <Link
                  target="_blank"
                  className="p-1 border-0"
                  to="https://supportafterabortion.com/resources/unraveled-roots/"
                  onClick={handleToggle}>
                  Unraveled Roots
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/SupportMen.pdf"
                  target="_blank"
                  onClick={() => {
                    setActive("/SupportMen.pdf");
                    setExpanded(false);
                  }}>
                  Impact on Men
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/center-register"
                  onClick={handleToggle}>
                  Affiliate-sign up
                </Link>

                <Link
                  className="p-1 border-0"
                  to="/hbcu"
                  onClick={() => setActive("/HBCU Project")}>
                  HBCU Project
                </Link>

                {!token && (
                  <Link
                    className=" p-1 border-0"
                    to="/login/"
                    onClick={handleToggle}>
                    Login
                  </Link>
                )}
                {token && (
                  <div ref={dropdownRef}>
                    <Link
                      className=" p-1 border-0"
                      onClick={() => setShowDropdown(!showDropdown)}>
                      {user?.firstName}
                    </Link>

                    <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                      <Dropdown.Item
                        onClick={() => {
                          handleToggle();
                          setShowDropdown(false);
                          setActive();
                          setExpanded(false); // Close the navbar only after the action
                          navigate("/profile");
                        }}>
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          setExpanded(false); // Close the navbar only after the action
                          dispatch(logout({ navigate }));
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                )}
              
              </Nav>
            </Navbar.Collapse> */}
            <button
              className={`${style.loginbtn}`}
              onClick={() => {
                setActive();
                navigate("/donate-to-us");
                setExpanded(false);
              }}>
              Donate
            </button>
          </Navbar>
        </div>
    

        <div className={style.smallscreennavbar}>
          <div className={style.smallhead}>
            <div>
              <Link to="/" onClick={() => setActive(1)}>
                <img
                  className={style.header_logo}
                  src="/whitelogo.svg"
                  alt="smallscreenlogo"
                />
              </Link>
            </div>
            <div>
              <button
                className={style.loginbtn}
                type="button"
                onClick={() => {
                  setActive();
                  navigate("/donate-to-us");
                }}>
                Donate
              </button>
            </div>
          </div>

          <div>
            <Nav className={style.navbar}>
              <div
                className={`position-relative `}
                onClick={() => setShowAboutDropdown(!showAboutDropdown)}>
                <Link
                  className="p-1 border-0"
                  to="/aboutus/"
                  onClick={() => {
                    setActive("/aboutus/");
                  }}>
                  About Us
                </Link>

                <Dropdown.Menu
                  className={style.drpdwn}
                  show={showAboutDropdown}>
                  <Dropdown.Item
                    className={`${style.drpcontent} ${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("coresection");
                      setShowAboutDropdown(false);
                    }}>
                    Core Beliefs
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("doitsection");
                      setShowAboutDropdown(false);
                    }}>
                    How We Do It
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("foundersection");
                      setShowAboutDropdown(false);
                    }}>
                    About the Founder
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShowAboutDropdown(false)}
                    as="a"
                    target="_blank"
                    href="https://supportafterabortion.com/resources/unraveled-roots/">
                    Unraveled Roots
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>

              <Link className="p-1 border-0" to="/hbcu" onClick={handleToggle}>
                HBCU Project
              </Link>

              <Link
                className="p-1 border-0"
                to="/SupportMen.pdf"
                target="_blank"
                onClick={handleToggle}>
                Impact on Men
              </Link>

              <Link
                className="p-1 border-0"
                to="/center-register"
                onClick={handleToggle}>
                Affiliate-sign up
              </Link>

              <div>
                {!token && (
                  <Link
                    className="p-1 border-0"
                    to="/login"
                    onClick={handleToggle}>
                    Login
                  </Link>
                )}
                {token && (
                  <div ref={dropdownRef}>
                    <Link
                      className="p-1 border-0"
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}>
                      {user?.firstName}
                    </Link>

                    <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          setActive();
                          navigate("/profile");
                        }}>
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          dispatch(logout({ navigate }));
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                )}
              </div>
            </Nav>
          </div>
        </div>

        <div className={`container  ${style.bigscreen}`}>
          <Nav className={style.navbar}>
            <div>
              <Link
                to="/"
                onClick={() => setActive(1)}
                style={{ borderBottom: "0px" }}>
                <img
                  className={style.header_logo}
                  src="/whitelogo.svg"
                  alt="bigscreenlogo"
                />
              </Link>
            </div>
            <div className={style.navmenus}>
              <div
                className={`position-relative ${style.nav}`}
                onMouseEnter={() => setShowAboutDropdown(true)} // Open dropdown on hover
                onMouseLeave={() => setShowAboutDropdown(false)}>
                <Link
                  className={active === "/aboutus/" ? style.navactive : ""}
                  to="/aboutus/"
                  onClick={() => {
                    setActive("/aboutus/");
                  }}>
                  About Us
                </Link>

                <Dropdown.Menu
                  className={style.drpdwn}
                  show={showAboutDropdown}>
                  <Dropdown.Item
                    className={`${style.drpcontent} ${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("coresection");
                      setShowAboutDropdown(false);
                    }}>
                    Core Beliefs
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("doitsection");
                      setShowAboutDropdown(false);
                    }}>
                    How We Do It
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`${style.cursor_hand}`}
                    onClick={() => {
                      scrollToSection("foundersection");
                      setShowAboutDropdown(false);
                    }}>
                    About the Founder
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setShowAboutDropdown(false)}
                    as="a"
                    target="_blank"
                    href="https://supportafterabortion.com/resources/unraveled-roots/">
                    Unraveled Roots
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>

              <div className={style.nav}>
                <Link
                  className={active === "/HBCU Project/" ? style.navactive : ""}
                  to="/hbcu"
                  onClick={() => setActive("/HBCU Project")}>
                  HBCU Project
                </Link>
              </div>

              <div className={style.nav}>
                <Link
                  className={
                    active === "/SupportMen.pdf" ? style.navactive : ""
                  }
                  to="/SupportMen.pdf"
                  target="_blank"
                  onClick={() => setActive("/SupportMen.pdf")}>
                  Impact on Men
                </Link>
              </div>

              <div className={style.nav}>
                <Link
                  className={`${
                    active === "/center-register" ? style.navactive : ""
                  } `}
                  to="/center-register"
                  onClick={() => setActive("/center-register")}>
                  Affiliate-sign up
                </Link>
              </div>
              <div className={style.nav}>
                {!token && (
                  <Link
                    className={`${active === "/login" ? style.navactive : ""} `}
                    to="/login"
                    onClick={() => setActive("/login")}>
                    Login
                  </Link>
                )}
                {token && (
                  <div ref={dropdownRef}>
                    <Link
                      className={`${
                        active === "/user?.firstName" ? style.navactive : ""
                      } `}
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}>
                      {user?.firstName}
                    </Link>
                    <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          setActive();
                          navigate("/profile");
                        }}>
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          dispatch(logout({ navigate }));
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                )}
              </div>
            </div>
            <div>
              <button
                className={style.loginbtn}
                type="button"
                onClick={() => {
                  setActive();
                  navigate("/donate-to-us");
                }}>
                {" "}
                Donate
              </button>
            </div>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
