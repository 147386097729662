import React from "react";
import style from "./auth.module.css"
import { Helmet } from "react-helmet";
const CenterSucess = () => {
  return (
    <>
      <Helmet>
    <title>Thank-you - I Stand With Forgotten Women</title>
    <meta name="description" content="Thank you for joining our crowdfunding platform! As a valued member, we are dedicated to supporting your vital pro-life mission. Together, we will amplify our efforts to promote and protect the sanctity of life. Check your email for login details!" />
    <meta name="keywords" content="Crowdfunding,Support,Advocacy,Pro-life,Community,Connection,Mission,Resources,Impact,Dedication"/>
  </Helmet>
    <div>
  
  <img className="w-100" src="/newmike.png" alt="banner" />
      <div className="container">
        <div className={style.CenterSucesscontainer}>
        <p>
          Hi, as the founder and president of I Stand with the Forgotten Women,
          I want to personally thank you for joining our crowdfunding platform.
          I want you to know that we are fully committed to supporting the
          incredible work you’re doing on behalf of our cause. We believe that
          the essential services and advocacy you provide are invaluable in
          promoting and protecting the sanctity of life. Also, if you encounter
          an urgent mission-related need, please contact us. As a member of our
          family, we will use whatever connections are available to us to help
          you meet those needs.
        </p>

        <p>
          By joining our community, we offer a unique opportunity to connect
          with a wider audience who share a passion for this important mission
          you’re carrying out on behalf of our Lord. And we are truly honored to
          be a part of helping amplify the impact you are making every day.
          Together, we will rally more support and resources to ensure every
          life is valued and protected.
        </p>

        <p>
        We are excited about supporting the amazing work you do for the pro-life cause.
        </p>

        <p>
        Thank you for your continued dedication to this vital mission.
        </p>

        <h2>Please check your email for login details. If you do not see the email in your inbox, please check your spam folder.</h2>
      </div>
      </div>
    </div>
    </>
  );
};

export default CenterSucess;
