import React from "react";
import style from "./donation.module.css";
import Newsletter from "../home/newsletter";
import { Helmet } from "react-helmet";

const Letter = () => {
  return (
    <>
        <Helmet>
    <title>Letter - I Stand With Forgotten Women</title>
    <meta name="description" content="Join Mike Goss in inspiring women like Janet to embrace life, overcome fear, and make empowered choices. Become a 12k member today and support a future of hope." />
    <meta name="keywords" content="Better selves.Unplanned pregnancy,Moral dilemma,Abortion consequences,Empowering women,Compassionate help,Fear vs. regret,Pro-life support,Choosing life,Emotional aftermath of abortion"/>
  </Helmet>
      <div className={style.lettermain}>
        <img className="w-100" src="/letterimage.jpg" alt="letter"></img>
      </div>
      <div className="container">
        <div className={style.textimgconatiner}>
          <div className={style.pcontainer}>
            <p>FOLLOW US</p>
          </div>
          <div className={style.letterimagecontainer}>
            <a href="https://www.facebook.com/share/hUuQTv9TUqkxVtBM/?mibextid=LQQJ4d" target="_blank">
              <img src="/fbsvg.svg" alt="facebook"></img>
            </a>
            <a href="https://www.youtube.com/@istandwiththeforgottenwomen" target="_blank">
              <img src="/utubesvg.svg" alt="youtube"></img>
            </a>
            <a href="https://www.instagram.com/istandwithforgottenwomen/" target="_blank">
              <img src="/instasvg.svg" alt="instagram"></img>
            </a>
            <a href="">
              <img src="/wifisvg.svg" alt="wifi"></img>
            </a>
            <a href="https://x.com/4gottenwomen" target="_blank">
              <img src="/twitternew.png" alt="twitter"></img>
            </a>
          </div>
        </div>
        <div className={style.lettermaincontainer}>
        
          <h2>Our Better Selves </h2>
          <p>
            In every situation, we are confronted with a pivotal decision where
            we can choose to respond in a manner that reflects our better selves
            or give way to the impulses of our lower selves. That decision
            influences not only the present moment but plays a significant role
            in shaping who we become.
          </p>
          <p> <span>Dear Friend,</span></p>
          <p>
            Regardless of one's viewpoint, the occurrence of 70 million
            abortions, with an estimated 40 million only because of perceived
            insurmountable obstacles and countless <span>women struggling in silence  </span>  with the emotional
            repercussions, cannot be seen as an embodiment of our better selves.
          </p>
          <p>
            Here’s a story, just one of many, that captures the essence of this
            moral dilemma.
          </p>

          <p>
            Janet (not her real name) was a young woman with dreams as vast as
            the ocean. She was driven, compassionate, and full of life, with
            aspirations of making a difference in the world
          </p>
          <p>But life, as it often does, threw her an unexpected curveball. 
          </p>
          <p>
          At 22, she found herself facing an unplanned pregnancy. Overwhelmed and afraid, she felt her <span>entire future was slipping away. 
          </span> 
        
          </p>
          <p>
          The pressure from her boyfriend, and the anxiety of disappointing her parents weighed heavily on her. 
          </p>
          <p>
            <span>
            Everywhere she turned, the world was telling her there was only one option: abortion.
            </span>
          </p>
          <p>
          Janet found herself isolated, drowning in a sea of conflicting emotions. She loved the child growing inside her, but… 
            <span> the fear of the unknown, </span> of the life-altering changes that motherhood would bring, was overpowering. 
            
          </p>
          <p>
          She had always believed in doing the right thing, but now, she wasn’t sure what the right thing was.
          </p>
          <p>
          Finally, after days of sleepless nights and tearful prayers, Janet decided. She walked into the clinic heart heavy with sorrow. The procedure was quick, but 
            <span> the emotional aftermath was anything but. 
            </span>
          </p>
          <p>
            The weight of her choice settled deep within her soul, a burden she would carry for years to come<span>—and shame likely forever.
            </span>
          </p>
          <p>The world around her moved on, but Janet couldn’t. 
          </p>
          <p>
          She had thought that abortion would bring her relief, an escape from an impossible situation, but instead, it brought profound emptiness, a void that nothing could fill.
          </p>
          <p>
          She couldn't shake the feeling that she had lost a part of herself in that fleeting moment of fear—a part that represented her better self.
          </p>
          <p>
            <span>
            If only someone had explained to her that fear is temporary, but regret is permanent. 
            </span>
          </p>
          <p>
          Janet’s story by no means is unique. It echoes the experiences of millions of women who, like her, felt cornered as though there was no other way. While solving one problem, they created another. 
          </p>
          <p>
            <span>
            The truth is while abortion may eliminate the immediate crisis, it does not address the root causes—
            </span>
            the fears, the lack of support, and societal pressures—that led to that moment of desperation.

          </p>
          <p>
          In Janet’s case, and so many others, the decision to abort was not made from a place of empowerment but from a place of fear. 
          </p>
          <p>
            <span>
            It was a decision born out of helplessness, not hope—weakness, not strength. 
            </span>
          </p>
          <p>
          While it provided temporary relief it <span> didn’t represent her better self—and she knew it. </span> It was a response to a whispering voice and the chorus in the public square that convinced her the best solution was to end the life she was carrying rather than allow it to interrupt hers. 
          </p>
          <p>
            {" "}
            <span>But what if…  </span>
          </p>
          <p>There had been another voice?</p>
          <p>What if…</p>
          <p>
          Janet had heard a that offered compassionate help and hope she desperately needed to make a decision that aligned with her core values? 

          </p>
          <p>
            <span>What if…</span>
          </p>
          <p>
          Instead of feeling cornered, she felt empowered. Empowered to choose life, embrace the challenges ahead, and realize it 
            <span > was not the end of her world ? </span>  Instead, of applying a permanent solution to a temporary problem that could have been
            <span> overcome if she had someone to stand with her.</span>
          </p>
          <p>
            <span>At some point we must decide…</span>
          </p>
          <p>Whether to continue yielding to our lesser selves
          </p>
          <p>
          Which does bring temporary relief but erodes the dignity and value we place on our lives—drawing into question our ability to <span> empathize </span>
            with those whose conscience dictates a different choice. 

          </p>
          <p>
          And worse… breeding an ever-increasing irreverence for life inside and outside of it. 
          </p>
          <p>
            <span>
          Or chart a new way forward. One that inspires us to ‘our better’ selves, representing who we truly are? 

            </span>
          </p>
          <p>
          The truth: The real battleground over this culture war is being fought inside us. 
          </p>
          <p>
            <span>This is not our ‘best’ selves, and we know it.  
            </span>
          </p>
          <p>
          We know that hardship and inconvenience can never outweigh the inherent dignity of an unrepeatable life. 
          </p>
          <p>
          We know that abortion must not be the 
            <span>
              {" "}
              only tool 
            </span>
            in our toolkit <span>for addressing unplanned pregnancies.
            </span>
          </p>
          <p>
          If you agree the time has come for us to resolve this moral conflict, then stand with us.
          </p>
          <p>
          Regardless, the one area we should all be able to agree on is that the fewer abortions, the better. 
          </p>
          <a href="#">
          Not just for the sake of the unborn but for the sake of the women who carry them, for the sake of the fathers who conceived them, and for the sake of our humanity.  
          </a>
          <p>Our better selves are demanding it.</p>
          <p>Thank you!</p>
          <p>Founder and President</p>
          <p>Mike Goss</p>

          <h5>Become a 12k member today!</h5>
          <p>
          We need 12,000 compassionate souls like yourself willing to set aside $12.00 a month (the price of Jumbo Jack, curly fires and shake) or any amount, $20, $30, $50 or whatever. It will help us prove to the Janets of tomorrow that not everyone has forgotten. 
          </p>

          <div className={style.letterbutton}>
            <a href="/donate-to-us/">
              <button>Stand With Us</button>
            </a>
          </div>
        </div>
      </div>
      <div>
        <img className="w-100" src="/blog.png" alt="blog"></img>
      </div>
      <Newsletter></Newsletter>
    </>
  );
};

export default Letter;
