import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import style from './floatingbutton.module.css';

const FLoatingbutton = () => {
  
  const navigate = useNavigate(); 
  const handleClick = () => {
    navigate('/donate-to-us'); 
  };

  return (
    <>
        <div className={style.floatbutton}>
          <button onClick={handleClick}>D<br />O<br />N<br />A<br />T<br />E</button>
        </div>

    </>
  );
};

export default FLoatingbutton;
