import React, { useState } from "react";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import style from "./auth.module.css";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { registerUser } from "../../apis/authApis";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  
  };

  return (
    <>
    <Helmet>
    <title>Register - I Stand With The Forgotten Women</title>
    <meta name="description" content="Sign up for a new account today! Complete the registration form to access exclusive features. Safe and secure – agree to our Terms of Use and Privacy Policy." />
    <meta name="keywords" content="Create account,Register new account,First name,Last name,Email registration,Enter password,Terms of Use,Privacy Policy,Account creation,User registration"/>
  </Helmet>
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "80vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} sm={8} md={6} lg={4} className="mx-auto p-0">
          <div className={style.authBox}>
            <h3 className="text-center mb-4 ">Create a new account below</h3>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                role: "user",
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .max(255)
                  .required("FirstName is required"),
                lastName: Yup.string()
                  .max(255)
                  .required("LastName is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                if (!isChecked) {
                 toast.error("You must agree to the Terms of Use and Privacy Policy")
                  return;
                }

                setDisableButton(true);
                try {
                  setStatus({ success: false });
                  setSubmitting(false);
                  dispatch(registerUser(values, navigate)).then(() => {
                    setDisableButton(false);
                  });
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                  setDisableButton(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirstName"
                      className={
                        touched.firstName && errors.firstName
                          ? "is-invalid"
                          : ""
                      }
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName)}
                    />
                    {touched.firstName && errors.firstName && (
                      <Form.Text className=" text-danger">
                        {errors.firstName}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LastName"
                      className={
                        touched.lastName && errors.lastName ? "is-invalid" : ""
                      }
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName)}
                    />
                    {touched.lastName && errors.lastName && (
                      <Form.Text className=" text-danger">
                        {errors.lastName}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      className=
                        {`${style.custominput}  ${touched.email && errors.email ? "is-invalid" : ""}`}
                        // touched.email && errors.email ? "is-invalid" : ""
                   
                      type="email"
                      placeholder="Enter email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <Form.Text className=" text-danger">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        className={
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="********"
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.password && errors.password)}
                      />
                      <InputGroup.Text
                        style={{ cursor: "pointer" }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </InputGroup>

                    {touched.password && errors.password && (
                      <Form.Text className=" text-danger">
                        {errors.password}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Check
                      type="checkbox"
                      label="I have read and agree to the Terms of Use and Privacy Policy."
                      onChange={handleCheckboxChange}

                    />
                  </Form.Group>

                  <Button
                    className="w-100 authBlubtn"
                    variant="primary"
                    type="submit"
                    disabled={disableButton}
                  >
                    Create New Account
                    {disableButton && (
                      <Spinner
                        className="ms-3"
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4">
              Already have account? <Link to="/login">Login</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Register;
