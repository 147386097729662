import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import { Col, Form, ProgressBar, Row } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";

import { fileBaseUrl } from "../../utils/api";
import {
  oneTimePayment,
  createSubscription,
  getRecentTransaction,
  AdminPayment,
  createAdminSubscription,
} from "../../apis/paymentApis";
import { useDispatch } from "react-redux";
import { getToken } from "../../utils/api";
import { toast } from "react-toastify";
import TransactionStatus from "../../modals/transaction/TransactionStatu";
import { getCenterById } from "../../apis/CenterApis";

import {
  getTotalTransactionandPercentage,
  fetchConnectedAccount,
} from "../../apis/paymentApis";
import useGetUser from "../../customHook/useGetUser";
import { jwtDecode } from "jwt-decode";
import { Helmet } from "react-helmet";

const Donation = () => {
  const token = getToken();
  const [searchParams] = useSearchParams();
  useGetUser();
  const location = useLocation();

  const dispatch = useDispatch();
  const [centerData, setCenterData] = useState(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  // const [isdata, setIsData] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);

  const [showTransactionStatus, setShowTransactionStaus] = useState(false);
  const [donationList, setDonationList] = useState(null);
  const [centerDetail, setCenterDetail] = useState();
  const [usercomment, setUserComment] = useState("");
  const [email, setEmail] = useState("");

  const fetchRecentTransaction = (id) => {
    if (id) {
      dispatch(getRecentTransaction({ id })).then((res) => {
        if (res?.transactions) setDonationList(res.transactions);
      });
    }
  };

  useEffect(() => {
    const success = searchParams.get("success");
    if (success === "true") {
      setShowTransactionStaus(true);
    }
  }, [searchParams]);

  const filesData = (value) => {
    const fileExtension = value?.media?.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    // const videoExtensions = ["mp4", "avi", "mov", "mkv"];
    const youtubePattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    console.log("value", value?.media);
    if (imageExtensions.includes(fileExtension)) {
      value.type = "image";
    } else if (youtubePattern.test(value.media)) {
      console.log("valueenter");
      value.type = "youtube";
    } else {
      value.type = "unsupported";
    }

    return value;
  };

  // Extracting the ID manually from the pathname
  const path = location.pathname;
  const id = path.split("/").pop();

  useEffect(() => {
    if (location.state) {
      fetchRecentTransaction(id);
      setCenterData(location.state);
    }
    if (!location.state) {
      dispatch(getCenterById({ id })).then((res) => {
        if (res?.centerData) {
          fetchRecentTransaction(id);
          setCenterData(filesData(res?.centerData));
        }
      });
    }
    dispatch(getTotalTransactionandPercentage({ id: id })).then((res) => {
      if (res?.result) {
        setCenterDetail(res?.result);
      }
    });
    // setIsData(true);
  }, [location]);

  useEffect(() => {
    const fetchConnect = async () => {
      try {
        const accountData = await fetchConnectedAccount({ id: id });
        console.log("Account", accountData);
        setConnectedAccount(accountData);
      } catch (err) {
        console.error("Error fetching connected account:", err);
      }
    };

    if (id) {
      fetchConnect();
    }
  }, [id]);
  const handleDateConversion = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const formattedDateTime = dateObject.toLocaleDateString();
    return formattedDateTime;
  };
  const handlePlay = (url) => {
    setVideoUrl(url);
    setShowVideoPlayer(true);
  };
  const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button
  const [connectedAccount, setConnectedAccount] = useState(null);
  const [Id, setId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };

  useEffect(() => {
    if (token) {
      console.log("Entertoken");
      const decoded = jwtDecode(token);
      console.log("decoded", decoded);
      setId(decoded?.sub);
      setEmail(decoded?.email);
      setIsLoggedIn(true);
    }
  }, []);

  const handleDonate = () => {
    if (!amount) {
      toast.error("Please select a  amount for proceed the payment");
      return;
    }
    if (!email) {
      toast.error("Please enter the email address");
      return;
    }

    const clearamount = amount.replace(/,/g, "");

    if (!isNaN(clearamount) && Number(clearamount) >= 0) {
      console.log("enter");
      if (selectedFrequency === "1" && connectedAccount) {
        console.log("ente rinto");
        dispatch(
          oneTimePayment({
            amount: clearamount,
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id: Id,
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
          }
        });
      } else if (selectedFrequency == "month" && connectedAccount) {
        dispatch(
          createSubscription({
            amount: amount,
            interval: "month",
            centerId: centerData?._id ? centerData._id : centerData.id,
            usercomment: usercomment,
            email: email,
            _id: Id,
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
          }
        });
      } else if (!connectedAccount && selectedFrequency === "1") {
        dispatch(
          AdminPayment({
            amount: amount,
            email: email,
            id: Id,
            centerId: centerData?._id ? centerData._id : centerData.id,
          })
        ).then((res) => {
          if (res?.data) {
            window.location.href = res?.data;
          }
        });
      } else {
        dispatch(
          createAdminSubscription({
            amount: amount,
            interval: "month",
            email: email,
            id: Id,
            centerId: centerData?._id ? centerData._id : centerData.id,
          })
        ).then((res) => {
          if (res?.url) {
            window.location.href = res?.url;
          }
        });
      }
    } else {
      toast.error("Invalid amount. Please enter a valid number.");
      return;
    }
  };

  if (!isLoggedIn) {
    localStorage.setItem("email", email);
  }
  console.log("Comment", usercomment);

  function getYouTubeVideoId(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }
  

  return (
    <>
      <Helmet>
        <title>
          {centerData?.id != "66f2a41ba8462a390b54d2b4" &&
          centerData?.centerName
            ? `Donation - ${centerData.centerName} - I Stand With The Forgotten Women`
            : "Donation - Janet - I Stand With The Forgotten Women"}
        </title>
        <meta
          name="description"
          content="Join us in empowering women and families facing unplanned pregnancies. Your donation helps provide vital resources, support, and alternatives to abortion through our network of pregnancy care centers. Together, we can create a positive impact in our communities."
        />
        <meta
          name="keywords"
          content="Mobile Medical Unit,Pregnancy Support,Alternatives to Abortion,Community Outreach,Health Essentials,Donation Campaign,Prenatal Care,Empowerment,Fundraising,Education"
        />
      </Helmet>
      <div className={style.mainsection}>
        <div className={style.donationcontainer}>
          <div className="container">
            <div className={style.maindonation}>
              <Row>
                <Col md={12} xl={7} lg={12} className="p-0">
                  <div className={style.maincenterform}> 
                    <div className={`${style.centername}`}>
                      {centerData?.centerName}
                    </div>
                    <div className={style.centerLocation}>
                      {centerData?.address}, {centerData?.city}{" "}
                      {centerData?.state} {centerData?.country}
                    </div>

                    <div>
                    <div className="position-relative image-container ">
                      {centerData?.type === "unsupported" && (
                        <img
                          className="w-100 "
                          src={`/defaultCenter.png`}
                          alt="center"
                        />
                      )}
                      {centerData?.type === "image" && (
                        <img
                          className="w-100 "
                          src={`${fileBaseUrl}${centerData?.media}`}
                          alt="center"
                        />
                      )}

                      {centerData?.type === "youtube" && (
                        <iframe
                          src={`https://www.youtube.com/embed/${getYouTubeVideoId(centerData.media)}`}
                          frameborder="0"
                          allow="encrypted-media"
                          allowfullscreen
                          title="video"
                          width="100%"
                          height="100%"
                        />

                     
                      )}
                      <div
                        class="overlay"
                        onClick={() =>
                          centerData?.type === "youtube" &&
                          handlePlay(centerData?.media)
                        }></div>
                    </div>

                    <div style={{  marginTop: "15px", fontSize:"20px" }}>
                      {centerData?.description}
                    </div>

                    <div className="mt-5 ">
                      <div
                        className="my-2"
                        style={{ fontSize: "28px"}}>
                        ${centerDetail?.totalAmount} USD raised of $
                        {centerDetail?.goalAmount?.toLocaleString()}
                      </div>
                      <ProgressBar
                        variant="info"
                        animated
                        now={centerDetail?.percentage}
                      />
                      <div
                        className="my-2"
                        style={{ fontSize: "28px"}}>
                        Recent Donations
                      </div>
                      <div className={style.donationtable}>
                        <table className="table m-0">
                          <thead className="thead-dark border-top border-bottom">
                            <tr>
                              <th scope="col">DATE</th>
                              {/* <th scope="col">DONOR</th> */}
                              <th scope="col">AMOUNT</th>
                            </tr>
                          </thead>
                          <tbody className={style.noborder}>
                            {donationList?.map((item, index) => (
                              <tr key={index} className="border-none">
                                <td>{handleDateConversion(item.createdAt)}</td>

                                <td style={{ color: "#06A1D7" }}>
                                  ${item.amount}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {!donationList && (
                        <div className=" p-5 text-center">
                          <h5> No Data Found </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  </div>
                </Col>
                <Col md={12} xl={5} lg={12} className="p-0">
                  <div className={style.paymentformmain}>
                    <div className={style.paymentForm}>
                      <div className={style.paymentHead}>
                        Choose A Donation Amount
                      </div>
                      <div className={style.donationpayment}>
                        <div className={style.paymentcontainer}>
                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "15"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="15"
                                        checked={amount === "15"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$15</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "50"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="50"
                                        checked={amount === "50"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$50</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "100"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="100"
                                        checked={amount === "100"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$100</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "200"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="200"
                                        checked={amount === "200"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$200</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={6}>
                              <div
                                className={
                                  amount === "500"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="500"
                                        checked={amount === "500"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$500</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div
                                className={
                                  amount === "1000"
                                    ? style.selectedLabel
                                    : style.donateinputcontainer
                                }>
                                <label>
                                  <div className={style.innerlabel}>
                                    <div className={style.smallscreeninput}>
                                      <input
                                        type="radio"
                                        name="amount"
                                        value="1000"
                                        checked={amount === "1000"}
                                        onChange={handleAmountChange}
                                      />

                                      <span>$1000</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Form.Group
                          className="my-3"
                          controlId="formBasicCheckbox">
                          <Form.Check
                            className={style.donationcomment}
                            type="checkbox"
                            label="Write us a comment"
                            aria-label="usercomment"
                            value={usercomment}
                            onClick={() => setShowTextArea(!showTextArea)}
                          />
                          {showTextArea && (
                            <textarea
                              className={`${style.commentBox}`}
                              type="textarea"
                              placeholder="Your Comment"
                              aria-label="usercomment"
                              value={usercomment}
                              onChange={(e) => setUserComment(e.target.value)} // Update comment state
                            />
                          )}
                        </Form.Group>

                        <div className={style.amountdonation}>
                          <div className={style.labellink}>
                            Enter a custom donation amount
                          </div>

                          <div className={style.customAmountInput}>
                            <span className={style.currency}>$</span>

                            <input
                              type="text"
                              value={amount}
                              placeholder="Enter custom amount"
                              onChange={(e) =>
                                setSelectedAmount(e.target.value)
                              }
                              className={style.inputboxtext}
                            />
                          </div>

                          <div className={style.labellinkemail}>
                            Enter Email
                          </div>

                          <div className={style.customAmountInput}>
                            <input
                              type="email"
                              placeholder="Email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className={style.informationinput}
                              disabled={isLoggedIn} // Disable if logged in
                            />
                          </div>
                        </div>

                        <h3>Choose a donation frequency</h3>
                        <div className={style.donateradio}>
                          <div
                            className={
                              selectedFrequency === "1"
                                ? style.selectedLabel2
                                : style.labeldonate
                            }>
                            <div className={style.donationlabel}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="1"
                                  checked={selectedFrequency === "1"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>OneTime</span>
                              </label>
                            </div>
                          </div>

                          <div
                            className={
                              selectedFrequency === "month"
                                ? style.selectedLabel2
                                : style.labeldonate2
                            }>
                            <div className={style.donationlabel}>
                              <label className={style.labelcontent}>
                                <input
                                  type="radio"
                                  name="frequency"
                                  value="month"
                                  checked={selectedFrequency === "month"}
                                  onChange={handleFrequencyChange}
                                />

                                <span>Monthly</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className={style.donationbuttoncontainer}>
                          <div className={style.smallscreenabutton}>
                            <button onClick={handleDonate}>
                              Go to Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <VideoPlayer
          showVideoPlayer={showVideoPlayer}
          setShowVideoPlayer={setShowVideoPlayer}
          videoUrl={videoUrl}
          // isdata={isdata}
        />
        <TransactionStatus
          showTransactionStatus={showTransactionStatus}
          setShowTransactionStaus={setShowTransactionStaus}
        />
      </div>
    </>
  );
};

export default Donation;
